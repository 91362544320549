import React from "react";
import {ReactComponent as ReactLogoFacebook} from './svg/facebook.svg';
import {ReactComponent as ReactLogoInstagram} from './svg/instagram.svg';
import empresa from './images/equalitech.png';
import fsw from './images/fsw.png';

function Footer() {
  return (
    <div className="footer">
      <footer class="py-5 bg-light">
        <div class="trademark">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-4">
                <center>
                  <h7>XXIX Feria de Software</h7>
                  <br />
                  <img
                    class="img-fluid rounded mb-4 mb-lg-0"
                    src={fsw}
                    width="150"
                    height="150" 
                    alt="" />
                </center>
              </div>
              <div class="col-sm-2">
                <center>
                  <h7>Links</h7>
                  <br />
                  <a href="/#/sobre_dentistapp">¿Qué es?</a>
                  <br />
                  <a href="/#/sobre_nosotros">Nosotros</a>
                  <br />
                  <a href="/#/contacto">Contacto</a>
                </center>
              </div>
              <div class="col-sm-2">
                <center>
                  <h7>Redes sociales</h7>
                  <br />
                  <a href="https://www.facebook.com/DentistApp-by-Equalitech-103928308705558">
                    <ReactLogoFacebook/>
                  </a>
                  <br />
                  <a href="https://www.instagram.com/dentistapp.equalitech/">
                    <ReactLogoInstagram style={{color: "#2d8cff"}}/>
                  </a>
                </center>
              </div>
              <div class="col-sm-4">
                <center>
                  <h7>Desarrollado por</h7>
                  <br />
                  <img
                    class="img-fluid rounded mb-4 mb-lg-0"
                    src={empresa}
                    width="200"
                    height="200" 
                    alt="" />
                </center>
              </div>
            </div>
          </div>
          <div>
            <p class="m-0 text-center text-black">
              Universidad Técnica Federico Santa María 
            </p>
            <p class="m-0 text-center text-black">
              EqualiTech &copy; 2021
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;