import React from "react";
import {ReactComponent as ReactLogoFacebook} from './svg/facebook - copia.svg';
import {ReactComponent as ReactLogoInstagram} from './svg/instagram - copia.svg';
import {ReactComponent as ReactEmail} from './svg/email - copia.svg';
import contacto from './images/contacto.webp';

function Contact() {
  return (
    <div className="contact">
      <div class="container">
        <div class="row align-items-center my-5">
          <div class="col-lg-7">
            <img
              class="img-fluid rounded mb-4 mb-lg-0"
              src={contacto}
              alt=""
              width="500"
              height="500"
            />
          </div>
          <div class="col-lg-5">
            <h2>Contacto</h2>
            <a class="nav-link" href="https://www.facebook.com/DentistApp-by-Equalitech-103928308705558" class="test">
              <h4><ReactLogoFacebook/> DentistApp by Equalitech</h4>
            </a> 
            <a class="nav-link" href="https://www.instagram.com/dentistapp.equalitech/" class="test">
               <h4><ReactLogoInstagram /> dentistapp.equalitech</h4>
            </a>
            <a class="nav-link" href="mailto:dentistapp.equalitech@gmail.com" class="test">
              <h4><ReactEmail/> dentistapp.equalitech@gmail.com</h4>
            </a>
          </div>
        </div>
      </div>
      <div class="espacio">
      </div>
    </div>
  );
}

export default Contact;