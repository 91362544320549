import React from "react";
import empresa from './images/equalitech.png';
import andres from './images/andres.png';
import melanie from './images/melanie.png';
import natalia from './images/natalia.png';
import nicolas from './images/nicolas.png';
import tomas from './images/tomas.png';
import vicente from './images/vicente.png';
import {ReactComponent as ReactEmail} from './svg/email.svg';
import {ReactComponent as ReactLinkedIn} from './svg/linkedin.svg';

function Us() {
  return (
    <div className="about">
      <div class="container-fluid" style={{backgroundColor: "#e5ebff"}}>
      <h2 style={{textAlign: "center", paddingTop: "5vh"}}>Quiénes Somos</h2>
        <div class="row">
          <div class="col-sm-1">
          </div>
          <div class="col-sm-5">
            <div class="alineado-vertical-icono">
              <img
                  class="img-fluid rounded mb-4 mb-lg-0"
                  src={empresa}
                  width="500"
                  height="500" 
                  alt="" />
            </div>
          </div>
          <div class="col-sm-5">
            <h5 style={{textAlign:"justify", paddingTop: "3vh"}}>La pre-empresa EqualiTech está conformada por 6 estudiantes de Ingeniería Civil Informática de la Universidad Técnica Federico Santa María, nos caracterizan la responsabilidad y habilidades a la hora de trabajar en equipo. 
            Nuestro objetivo es lograr a través de nuestros distintos proyectos un aporte real en la sociedad, facilitando de alguna manera la vida de las personas.</h5>
          </div>

          <div class="col-sm-1">
          </div>
          <div class="espacio">
          </div>
        </div>
      </div>
      <br/><br/><br/>
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-2">
          </div>
          <div class="col-sm-8">
            <div class="container-fluid">
              <div class="row">
                <div class="col-sm-5">
                  <div class="tarjeta-2">
                    <h2 style={{textAlign:"center",paddingBottom:50}}>Misión</h2>
                    <p>Nuestra misión consiste en realizar  aplicaciones de calidad, confiables y que otorguen buenos resultados.</p>
                    <br/>
                  </div>
                  <br/>
                </div>
                <div class="col-sm-2">
                </div>
                <div class="col-sm-5">
                  <div class="tarjeta-2">
                    <h2 style={{textAlign:"center",paddingBottom:50}}>Visión</h2>
                    <p>Nuestra visión es lograr una cuota de mercado considerable a nivel latinoamericano en todos nuestros proyectos.</p>
                  </div>
                  <br/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br/><br/><br/>
      <div class="banner-nosotros">
        <h2 style={{textAlign:"center",paddingBottom:50}}>Nuestro Equipo</h2>
        <div class="container-fluid">
          <div class="row">
            <div class="col-sm-1">
            </div>
            <div class="col-sm-10">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-sm-4">
                    <div class="caja">
                      <div class="tarjeta">
                        <img
                        class="img-fluid rounded mb-4 mb-lg-0 full-image"
                        src={melanie} 
                        alt="" />
                        <p><strong>Melanie Corletto</strong><br/>Product Manager</p>
                        <div class="container-fluid">
                            <div class="row">
                              <div class="col-2"></div>
                              <div class="col-1"><a href="mailto:melanie.corletto@sansano.usm.cl"><ReactEmail/></a></div>
                              <div class="col-1"></div>
                              <div class="col-1"><a href="https://www.linkedin.com/in/melanie-corletto-budez-a33057177/"><ReactLinkedIn/></a></div>
                            </div>
                          </div>  
                      </div>
                    </div>
                    <br/>
                  </div>
                  <div class="col-sm-4">
                      <div class="caja">
                        <div class="tarjeta">
                          <img
                            class="img-fluid rounded mb-4 mb-lg-0 full-image"
                            src={natalia} 
                            alt="" />
                            <p><strong>Natalia Herrera</strong><br/>Product Owner</p>
                            <div class="container-fluid">
                              <div class="row">
                                <div class="col-2"></div>
                                <div class="col-1"><a href="mailto:natalia.herrera@sansano.usm.cl"><ReactEmail/></a></div>
                                <div class="col-1"></div>
                                <div class="col-1"><a href="https://www.linkedin.com/in/natalia-herrera-lora-42947214b/"><ReactLinkedIn/></a></div>
                              </div>
                            </div>  
                        </div>
                        <br/>
                      </div>
                  </div>
                  <div class="col-sm-4">
                      <div class="caja">
                        <div class="tarjeta">
                          <img
                            class="img-fluid rounded mb-4 mb-lg-0 full-image"
                            src={tomas}
                            alt="" />
                            <p><strong>Tomás Berríos</strong><br/>Scrum Master</p>
                            <email/>
                            <div class="container-fluid">
                              <div class="row">
                                <div class="col-2"></div>
                                <div class="col-1"><a href="mailto:tomas.berrios@sansano.usm.cl"><ReactEmail/></a></div>
                                <div class="col-1"></div>
                                <div class="col-1"><a href="https://www.linkedin.com/in/tom%C3%A1s-berr%C3%ADos-reyes-87a6a820b/"><ReactLinkedIn/></a></div>
                              </div>
                            </div>   
                        </div>
                        <br/>
                      </div>
                  </div>
                </div>
              </div>

              <br />

              <div class="container-fluid">
                <div class="row">
                  <div class="col-sm-4">
                    <div class="caja">
                      <div class="tarjeta">
                        <img
                          class="img-fluid rounded mb-4 mb-lg-0 full-image"
                          src={andres} 
                          alt="" />
                        <p><strong>Andrés Shehadeh</strong><br/>Chief Backend</p>
                        <div class="container-fluid">
                          <div class="row">
                            <div class="col-2"></div>
                            <div class="col-1"><a href="mailto:andres.shehadeh@sansano.usm.cl"><ReactEmail/></a></div>
                            <div class="col-1"></div>
                            <div class="col-1"><a href="https://www.linkedin.com/in/andr%C3%A9s-shehadeh-0103a0140/"><ReactLinkedIn/></a></div>
                          </div>
                        </div>    
                      </div>
                      <br/>
                    </div>
                  </div>
                  <div class="col-sm-4">
                      <div class="caja">
                        <div class="tarjeta">
                          <img
                            class="img-fluid rounded mb-4 mb-lg-0 full-image"
                            src={nicolas} 
                            alt="" />
                            <p><strong>Nicolás Miranda</strong><br/>Marketing Manager</p>
                            <div class="container-fluid">
                              <div class="row">
                                <div class="col-2"></div>
                                <div class="col-1"><a href="mailto:nicolas.mirandat@sansano.usm.cl"><ReactEmail/></a></div>
                                <div class="col-1"></div>
                                <div class="col-1"><a href="https://www.linkedin.com/in/nicol%C3%A1s-ignacio-miranda-340246202/"><ReactLinkedIn/></a></div>
                              </div>
                            </div>  
                        </div>
                        <br/>
                      </div>
                  </div>
                  <div class="col-sm-4">
                      <div class="caja">
                        <div class="tarjeta">
                          <img
                            class="img-fluid rounded mb-4 mb-lg-0 full-image"
                            src={vicente}
                            alt="" />
                            <p><strong>Vicente Robles</strong><br/>Chief Frontend</p>
                            <div class="container-fluid">
                              <div class="row">
                                <div class="col-2"></div>
                                <div class="col-1"><a href="mailto:vicente.robles@sansano.usm.cl"><ReactEmail/></a></div>
                                <div class="col-1"></div>
                                <div class="col-1"><a href="https://www.linkedin.com/in/vicente-robles-764700211"><ReactLinkedIn/></a></div>
                              </div>
                            </div>  
                        </div>
                        <br/>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Us;