import React from "react";
import { Link, withRouter } from "react-router-dom";
import {ReactComponent as ReactLogo} from './svg/logo.svg';
import {ReactComponent as ReactLogoFacebook} from './svg/facebook.svg';
import {ReactComponent as ReactLogoInstagram} from './svg/instagram.svg';
//import styles from "./styled.module.css";

function Navigation(props) {
  return (
    <div className="navigation">
      <nav class="navbar navbar-expand-sm navbar-dark bg-dark navbar-static-top">
        <div class="container">
          <Link class="navbar-brand" to="/">
            <ReactLogo />
            DentistApp
          </Link>

          <div>
            <ul class="navbar-nav ml-auto" style={{flex:1, flexDirection:"row"}}>
              <li
                class={`nav-item  ${
                  props.location.pathname === "/" ? "active" : ""
                }`}
              >
              </li>
              <li
                class={`nav-item  ${
                  props.location.pathname === "/sobre_dentistapp" ? "active" : ""
                }`}
              >
                <Link class="nav-link" to="/sobre_dentistapp">
                  ¿Que es DentistApp?
                </Link>
              </li>
              <li
                class={`nav-item  ${
                  props.location.pathname === "/sobre_nosotros" ? "active" : ""
                }`}
              >
                <Link class="nav-link" to="/sobre_nosotros">
                  Sobre Nosotros
                </Link>
              </li>
              <li
                class={`nav-item  ${
                  props.location.pathname === "/contacto" ? "active" : ""
                }`}
              >
                <Link class="nav-link" to="/contacto">
                  Contacto
                </Link>
              </li>
              <li>
                <a class="nav-link" href="https://www.facebook.com/DentistApp-by-Equalitech-103928308705558">
                  <ReactLogoFacebook/>
                </a>
              </li>
              <li>
                <a class="nav-link" href="https://www.instagram.com/dentistapp.equalitech/">
                  <ReactLogoInstagram/>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default withRouter(Navigation);