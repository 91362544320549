import React from "react";
import SharePost  from './sharePost'; 
//import styles from "./styled.module.css";

function Home() {
  return (
    <div className="home">
      <div class="banner-home">
        <div class="row align-items-center">
          <div class="col-sm-7">
            <div class="embed-responsive embed-responsive-16by9">
              <iframe allowFullScreen="allowFullScreen" width="560" height="315" src="https://www.youtube.com/embed/T4D4P758CBo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"></iframe>
            </div>
          </div>
          <div class="col-sm-5">
            <h2 style={{textAlign: "justify", paddingTop: "3vh"}}>DentistApp</h2>
            <h5 style={{textAlign: "justify"}}>DentistApp es una aplicación móvil que se está desarrollando para ayudar y promover el cuidado bucal de las personas, con la ayuda de recordatorios de lavado de dientes, de estimaciones obtenidas con inteligencia artificial por medio de fotografías, y recomendaciones para visitar a profesionales del área de la salud bucal, entre otros.</h5>
            <br />
            <center>
              <h4>Las buenas ideas se comparten</h4>
            </center>
            <br/>
            <SharePost url="dentistapp.feriadesoftware.cl/" titulo="DentistApp - Equalitech" resumen="Pre-diagnósticos y asesoría odontológica en la comodidad de tu hogar"/>
          </div>
        </div>
      </div>
      <div class="espacio">
      </div>
    </div>
  );
}

export default Home;