import React from 'react';
import { WhatsappShareButton, FacebookShareButton, LinkedinShareButton, WhatsappIcon, FacebookIcon, LinkedinIcon } from 'react-share';

function sharePost(props) {
  console.log(props)
  return (
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-3">
        </div>
        <div class="col-sm-6">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-4">
                <center>
                  <FacebookShareButton url={props.url} quote={`${props.titulo} \n ${props.resumen}`} hashtag={"#DentistApp #FeriadeSoftware2021"}>
                    <FacebookIcon round={true} size={55} iconFillColor={"white"} />
                  </FacebookShareButton>
                </center>
              </div>
              <div class="col-sm-4">
                <center>
                  <LinkedinShareButton url={props.url} title={props.titulo} summary={props.resumen}>
                    <LinkedinIcon round={true} size={55} iconFillColor={"white"} />
                  </LinkedinShareButton>
                </center>
              </div>
              <div class="col-sm-4">
                <center>
                  <WhatsappShareButton url={props.url} title="Me gusto este proyecto, y a ti? Puedes saber más en " separator={""}>
                    <WhatsappIcon round={true} size={55} iconFillColor={"white"} />
                  </WhatsappShareButton>
                </center>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default sharePost