import React from "react";
import {ReactComponent as ReactLogo} from './svg/door.svg';
import {ReactComponent as ReactLogoBombilla} from './svg/lightbulb.svg';
import scanner from './images/scanner.png';
import dentista from './images/dentista.png';

function About() {
  return (
    <div className="about">
      <div class="banner">
        <h2 class="texto-banner" >Problema</h2>
        <div class="container-fluid">
          <div class="row">
            <div class="col-sm-3">
            </div>
            <div class="col-sm-6">
              <h5 style={{textAlign:"center"}}>Según estudios, un gran porcentaje de Chilenos no asiste al dentista la cantidad de veces
                recomendada según los expertos. Lo anterior, junto con las malas prácticas de higiene bucal,
                genera que gran parte de los chilenos padezca de alguna condición que afecte su salud bucal.
              </h5>
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-2">
          </div>
          <div class="col-sm-3">
            <center><ReactLogo/></center>
            <h4 class="texto">Oportunidad</h4>
            <h5 style={{textAlign:"justify"}}>La integración de Inteligencia Artificial, junto con la tecnología móvil, permite informarle a las personas sobre
            el estado actual de su dentadura y boca</h5>
          </div>
          <div class="col-sm-2">
          </div>
          <div class="col-sm-3">
            <center><ReactLogoBombilla/></center>
            <h4 class="texto">Innovación</h4>
            <h5 style={{textAlign:"justify"}}>DentistApp  surge como una forma de facilitar a las personas
            el que obtengan información sobre su estado bucal y dental, para así incentivar en Chile el cuidado por la
            salud dental</h5>
          </div>
          <div class="col-sm-2">
          </div>
        </div>
      </div>
      <div class="espacio">
      </div>
      <div class="banner-claro">
        <div class="texto-banner-claro">
          <hr />
          <h2 style={{color: "#002a46"}}>Beneficios</h2>
          <hr />
        </div>
      </div>
      <div class="espacio">
      </div>
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-5">
            <img
            class="img-fluid rounded mb-4 mb-lg-0"
            src={scanner}
            width="500"
            height="500" 
            alt="" />
          </div>
          <div class="col-sm-5">
            <div class="alineado-vertical">
              <h3 style={{textAlign:"center", color: "#2d8cff"}}>Usuarios</h3>
              <h4 style={{textAlign:"justify"}}>Los potenciales usuarios de DentistApp se beneficiarán gracias a la implementación
              de la estimación a través de fotografías, dicha estimación les entregará un informe de 
              su condición, junto con información sobre el profesional adecuado, para que asista oportunamente a un centro dental</h4>
            </div>
          </div>  
        </div>
      </div>
      <div class="espacio">
      </div>
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-1">
          </div>
          <div class="col-sm-5">
            <div class="alineado-vertical">
              <h3 style={{textAlign:"center", color: "#2d8cff"}}>Dentistas</h3>
              <h4 style={{textAlign:"justify"}}>Todo dentista registrado con DentistApp podrá aparecer como sugerencia
              para atender a nuestros usuarios, pudiendo llegar a un mayor rango de clientes</h4>
            </div>
          </div>
          <div class="col-sm-5">
            <img
              class="img-fluid rounded mb-4 mb-lg-0"
              src={dentista}
              width="500"
              height="500" 
              alt="" />
          </div>
          <div class="col-sm-1">
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;