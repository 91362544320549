import React from "react";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import { Navigation, Footer, Home, About, Contact, Us } from "./components";
function App() {
  return (
    <div className="App">
      <Router basename={"/"}>
        <Navigation />
        <Switch>
          <Route path="/" exact component={() => <Home />} />
          <Route path="/sobre_dentistapp" exact component={() => <About />} />
          <Route path="/contacto" exact component={() => <Contact />} />
          <Route path="/sobre_nosotros" exact component={() => <Us />} />
        </Switch>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
